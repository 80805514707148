export class EmotionCursors {
  min: string;
  ave: string;
  max: string;
}

export class Emotion {
  key: string;
  position: number;
  name: string;
  content: string;
  cursors: EmotionCursors;

  constructor(rawObj?: Partial<Emotion>) {
    if (!!rawObj) {
      Object.assign(this, rawObj);
    }
  }
}

export class Group {
  key: string;
  content: string;
  position: number;
  name: string;
  emotions: Emotion[] = [];

  // Convert emotions to array
  constructor(rawObj?: any) {
    // Remove emotions from rawObjWithoutEmotions
    const {emotions, ...rawObjWithoutEmotions} = rawObj;
    Object.assign(this, rawObjWithoutEmotions);
    // Fill emotions array
    Object.keys(emotions).forEach(key => {
      const emo = emotions[key];
      this.emotions.push(new Emotion({...emo, key: key}));
      this.emotions.sort((a, b) => a.position - b.position);
    });
  }

  public static rawToArray(raw): Group[] {
    const groups = [];
    Object.keys(raw).forEach(key => {
      const group = new Group(raw[key]);
      group.key = key;
      groups.push(group);
    });
    groups.sort((a, b) => a.position - b.position);
    return groups;
  }

  // Convert array to collection
  convertToCollection() {
    const emotionAsObjects: any = {};
    this.emotions.forEach(emotion => {
      emotionAsObjects[emotion.key] = emotion;
    });
    return emotionAsObjects;
  }
}
