export class Score {
  balance: number;
  averagePlus: number;
  averageMinus: number;
  emotions: {
    [keyof: string]: number;
  };

  constructor() {
    this.emotions = {};
  }
}
