import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { Alert } from '../models/alert';

@Injectable()
export class GlobalEventsService {

  public isLoading$: Subject<boolean> = new Subject();
  public isWaiting$: Subject<boolean> = new BehaviorSubject(true);
  public openAlert$: Subject<Alert> = new Subject();

  constructor() {}

  private changeWaiting(waiting: boolean) {
    this.isWaiting$.next(waiting);
  }

  openAlert(alert: Alert) {
    this.openAlert$.next(alert);
  }

  openWaiting() {
    this.changeWaiting(true);
  }

  closeWaiting() {
    setTimeout(() => {
      this.changeWaiting(false);
    }, 500);
  }

  private changeLoading(loading: boolean) {
    this.isLoading$.next(loading);
  }

  startLoading() {
    this.changeLoading(true);
  }

  stopLoading() {
    this.changeLoading(false);
  }
}
