export namespace DateHelper {

  export const getUTC = () => {
    return new Date().toISOString();
  };

  export const isTodayBetweenDates = (start, end) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    if (start) {
      const startDate = new Date(start);
      startDate.setHours(0, 0, 0, 0);
      if (startDate.getTime() > now.getTime()) {
        return false;
      }
    }

    if (end) {
      const endDate = new Date(end);
      endDate.setHours(0, 0, 0, 0);
      if (endDate.getTime() < now.getTime()) {
        return false;
      }
    }

    return true;
  };
}
