import { Component } from '@angular/core';

@Component({
  selector: 'app-global-waiting',
  templateUrl: './global-waiting.component.html',
  styleUrls: ['./global-waiting.component.scss']
})
export class GlobalWaitingComponent {

  constructor() {}
}
