import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpService } from './http.service';
import { LoggerService } from '../logger.service';

@Injectable()
export class ApiLocalService extends HttpService {

  constructor(
    protected http: HttpClient,
    protected logger: LoggerService
  ) {
    super(http, logger);
  }

  public prefixAndSuffixUrl(url) {
    return '../assets/data/api/' + url;
  }

}
