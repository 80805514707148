import { ENUM } from './enum';
import { Score } from './score';

export class Trial {
  id: string;
  campaignId: string;
  identity: TrialIdentity;
  created: string;
  started: string;
  finished: string;
  status: ENUM.TrialStatus;
  informations: {};
  results: Score;
}

export class TrialIdentity {
  email: string;

  constructor(rawObj?: Partial<TrialIdentity>) {
    if (!!rawObj) {
      Object.assign(this, rawObj);
    }
  }
}
