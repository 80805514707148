import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LoggerService } from '../logger.service';

@Injectable()
export class HttpService {

  constructor(
    protected http: HttpClient,
    protected logger: LoggerService
  ) { }

  public get(url): Promise<any> {
    url = this.prefixAndSuffixUrl(url);
    return this.http.get(url).toPromise();
  }

  public post(url, object): Promise<any> {
    url = this.prefixAndSuffixUrl(url);
    return this.http.post(url, object).toPromise();
  }

  public put(url, object): Promise<any> {
    url = this.prefixAndSuffixUrl(url);
    return this.http.put(url, object).toPromise();
  }

  public patch(url, object): Promise<any> {
    url = this.prefixAndSuffixUrl(url);
    return this.http.patch(url, object).toPromise();
  }

  public delete(url): Promise<any> {
    url = this.prefixAndSuffixUrl(url);
    return this.http.delete(url).toPromise();
  }

  public prefixAndSuffixUrl(url) {
    return url;
  }
}
