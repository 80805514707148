import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class LoggerService {

  public use: NGXLogger;

  constructor(
    private logger: NGXLogger
  ) {
    this.use = this.logger;
  }
}
