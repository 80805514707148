import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { CampaignsService } from '../../providers/campaigns.service';
import { BEMO } from '../../constants';
import { DateHelper } from '../../helpers/date.helper';
import { GlobalEventsService } from '../../providers/global-events.service';
import { LoggerService } from '../../providers/logger.service';
import { SessionService } from '../../providers/session.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public error: string;

  constructor(
    private router: Router,
    private logger: LoggerService,
    private events: GlobalEventsService,
    private session: SessionService,
    private campaignService: CampaignsService
  ) {

  }

  ngOnInit() {
    this.events.openWaiting();
    this.error = '';

    const url = this.router.url;
    this.logger.use.debug('Requested url', url);
    let id = url.substring(BEMO.ROUTES.AUTH.url.length + 2);

    if (!id && environment.development) {
      id = '-LCEixw5VvS1CNurfbuI';
    }

    if (!id || id === '') {
      this.logger.use.error('No campaign id');
      this.error = 'LANDING.NO_CAMPAIGN_LINK';
      this.events.closeWaiting();
      return;
    }

    this.logger.use.debug('Requested id', id || 'empty');
    this.fetchCampaign(id);
  }

  fetchCampaign(id: string) {
    this.campaignService.fetchById(id).then(campaign => {

      if (!campaign || !campaign.id) {
        this.logger.use.error('Unknown campaign id');
        this.error = 'LANDING.CAMPAIGN_KEY_UNKNOWN';
        this.events.closeWaiting();
        return;
      }

      if (!DateHelper.isTodayBetweenDates(campaign.start, campaign.finish)) {
        this.logger.use.error('Campaign out of date');
        this.error = 'LANDING.CAMPAIGN_OUT_OF_DATE';
        this.events.closeWaiting();
        return;
      }

      this.logger.use.log('Used campaign :', campaign);
      this.session.changeCampaign(campaign);
      this.launchSurvey();

    }, error => {
      this.error = 'COMMON.LOADING_ERROR';
      this.events.closeWaiting();
      this.logger.use.error('Erreur de chargement de la session', error);
    });
  }

  launchSurvey() {
    this.events.closeWaiting();
    this.router.navigateByUrl(BEMO.ROUTES.HOME.url);
  }
}
