import { Subject } from 'rxjs';

export class AlertButton {
  text: string;
  sub: Subject<void>;

  constructor(text, sub?) {
    this.text = text;
    this.sub = sub;
  }
}

export class Alert {
  title: string;
  message: string;
  buttons: AlertButton[];

  constructor(title, message, buttons) {
    this.title = title;
    this.message = message;
    this.buttons = buttons;
  }
}
