import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { BEMO } from '../../constants';

@Injectable()
export class RefreshGuardService implements CanActivate {

  constructor(
    private router: Router,
  ) {}

  canActivate() {
    if (!this.router.navigated) {
      this.router.navigateByUrl(BEMO.DEFAULT_ROUTE.url);
      return false;
    } else {
      return true;
    }
  }
}
