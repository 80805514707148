import { Injectable } from '@angular/core';

import { Campaign } from '../models/campaign';
import { Trial } from '../models/trial';

@Injectable()
export class SessionService {

  public trial: Trial;
  public campaign: Campaign;

  constructor() {}

  changeCampaign(campaign: Campaign) {
    this.campaign = campaign;
  }
}
