import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: true,
  development: false,
  backofficeUrl: 'http://backoffice.balance-emotionnelle.fr/reports/',
  database: {
    url: 'https://bemo-backoffice-prod.firebaseio.com',
    token: 'wCNMvXNugneOUyfXWDo6dhbGbe9iWmXV0cQtK1hC',
  },
  logs: {
    serverLoggingUrl: '',
    level: NgxLoggerLevel.WARN,
    serverLogLevel: NgxLoggerLevel.OFF
  },
  languages: {
    availables: [
      'fr',
      'en'
    ],
    default: 'fr'
  }

};
