import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Group } from '../../models/emotion';
import { LoggerService } from '../../providers/logger.service';

@Component({
  selector: 'app-emotion-splitter',
  templateUrl: './emotion-splitter.component.html',
  styleUrls: ['./emotion-splitter.component.scss']
})
export class EmotionSplitterComponent implements OnInit {

  @Input() group: Group;
  @Output() continue: EventEmitter<void> = new EventEmitter();

  constructor(
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.logger.use.log('Current emotions group is ' + this.group.key);
  }

  goToRating() {
    this.continue.emit();
  }
}
