export namespace ENUM {

  export enum PageBehavior {
    USER_IDENTIFICATOR = 1,
    EMOTION_RATING,
    EMOTION_SPLITTER,
    USER_FORM,
    SURVEY_END
  }

  export enum TrialStatus {
    CREATED = 1,
    SENT,
    STARTED,
    FINISHED,
    INFORMED,
    COMPUTED,
    ARCHIVED
  }

  export enum TrialGender {
    FEMALE = 'F',
    MALE = 'M'
  }

  export enum TrialLang {
    FRENCH = 'fr',
    ENGLISH = 'en'
  }
}
