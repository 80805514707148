import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { GlobalEventsService } from './providers/global-events.service';

@Component({
  selector: 'app-root',
  template: `
  <div class="app-container">
    <router-outlet></router-outlet>
  </div>
  <app-global-loader *ngIf="isLoading"></app-global-loader>
  <app-global-waiting *ngIf="isWaiting"></app-global-waiting>
  <app-global-alert></app-global-alert>
  `
})
export class AppComponent {
  dev = environment.development;
  isWaiting: boolean;
  isLoading: boolean;

  constructor(
    private events: GlobalEventsService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang(environment.languages.default);

    this.listenIsWaiting();
    this.listenIsLoading();
  }

  listenIsWaiting() {
    this.events.isWaiting$.subscribe(isWaiting => this.isWaiting = isWaiting);
  }

  listenIsLoading() {
    this.events.isLoading$.subscribe(isLoading => this.isLoading = isLoading);
  }
}
