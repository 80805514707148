import { Component } from '@angular/core';
import { Alert, AlertButton } from '../../models/alert';
import { GlobalEventsService } from '../../providers/global-events.service';

@Component({
  selector: 'app-global-alert',
  templateUrl: './global-alert.component.html',
  styleUrls: ['./global-alert.component.scss']
})
export class GlobalAlertComponent {

  public alert: Alert;

  constructor(
    private event: GlobalEventsService
  ) {
    this.event.openAlert$.subscribe( alert => {
      this.openAlert(alert);
    });
  }

  openAlert(alert: Alert) {
    this.alert = alert;
  }

  closeAlert(button: AlertButton) {
    if (button && button.sub) {
      button.sub.next();
    }
    this.alert = null;
  }
}
