import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { BEMO } from '../../constants';

@Injectable()
export class IsDevGuardService implements CanActivate {

  constructor(
    private _router: Router,
  ) {}

  canActivate() {
    if (environment.development) {
      return true;
    } else {
      this._router.navigate([BEMO.DEFAULT_ROUTE.url]);
    }
  }
}
