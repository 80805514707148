import { Injectable } from '@angular/core';

import { Campaign } from '../models/campaign';
import { ApiBemoService } from './requests/api-bemo.service';

@Injectable()
export class CampaignsService {
  private readonly RESSOURCE_LIBRARY = 'campaigns';

  constructor(
    private apiBemo: ApiBemoService
  ) {}

  public fetchById(id: string): Promise<Campaign> {
    const url = `${this.RESSOURCE_LIBRARY}/${id}`;
    return this.apiBemo.get(url) as Promise<Campaign>;
  }
}
