import { Component, HostListener, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { DateHelper } from '../../helpers/date.helper';
import { Alert, AlertButton } from '../../models/alert';
import { Emotion, Group } from '../../models/emotion';
import { ENUM } from '../../models/enum';
import { Score } from '../../models/score';
import { Trial } from '../../models/trial';
import { GlobalEventsService } from '../../providers/global-events.service';
import { LoggerService } from '../../providers/logger.service';
import { SessionService } from '../../providers/session.service';
import { TrialsService } from '../../providers/trials.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public readonly PAGES = ENUM.PageBehavior;
  public visibility: ENUM.PageBehavior; // Current page type
  public currentStep: number; // Current page number
  public totalStep: number; // Total pages number
  public progress: number; // % progression

  public groups: Group[]; // Emotion groups
  public currentIndexGroup: number; // Current group
  public currentIndexEmotion: number; // Current emotion in group

  public trial: Trial = new Trial();

  constructor(
    private events: GlobalEventsService,
    private trialsService: TrialsService,
    private logger: LoggerService,
    private session: SessionService
  ) {
    this.visibility = ENUM.PageBehavior.USER_IDENTIFICATOR; // First page to display
    this.currentIndexGroup = -1;
    this.currentIndexEmotion = -1;
    this.currentStep = 0;
    this.progress = 0;
  }

  /* tslint:disable */
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (environment.development) {
      if (event.key === 'r') {
        if (this.visibility === ENUM.PageBehavior.EMOTION_SPLITTER) {
          this.goToRating();
        } else if (this.visibility === ENUM.PageBehavior.EMOTION_RATING) {
          this.rateEmotion(Math.round(Math.random() * 10));
        }
      }
    }
  }
  /* tslint:enable */

  /**
   * Init course
   * Fetch all emotions
   * And preload background
   */
  ngOnInit() {
    Promise.all([
      this.trialsService.fetchAllEmotions(),
      this.preloadBackgroundImage()
    ])
    .then(results => {
      this.groups = results[0];
      this.calculateTotalStep();
      this.events.closeWaiting();
    });
  }

  /**
   * Preload background image
   */
  preloadBackgroundImage(): Promise<void> {
    const url = '/assets/images/background.png';
    return new Promise<void>((resolve) => {
      const image = new Image();
      image.onload = () => { resolve(); };
      image.onerror = () => { resolve(); };
      image.src = url;
    });
  }

  /**
   * Calculate total number of step
   * based on emotion number, form page number...
   */
  calculateTotalStep() {
    this.totalStep = 1; // Last screen

    // Splitt & rating screens
    this.groups.forEach(group => {
      this.totalStep++;
      if (group.emotions) {
        const keys = Object.keys(group.emotions).length;
        this.totalStep += keys;
      }
    });

    // Form screens
    this.totalStep += 2;
    const dimensions = this.session.campaign.dimensions;
    if (dimensions && dimensions.length > 0) {
      this.totalStep++;
    }
  }

  /**
   * FIRST STEP : Fill email
   * Event from output of app-user-identificator component
   * @param email
   */
  submitEmail(email: string) {
    this.logger.use.log('Email utilisé :', email);

    if (this.session.trial && this.session.trial.started) {
      this.trial = this.session.trial;
    }

    this.trial.identity = { email: email };
    this.trial.started = DateHelper.getUTC();
    this.trial.status = ENUM.TrialStatus.STARTED;
    this.trial.campaignId = this.session.campaign.id;

    if (this.session.trial && this.session.trial.started) {
      // Already started, start again, erase old trial
      this.trialsService.update(this.trial).then(() => {
        this.showNextScreen();
      });
    } else {
      // First start
      this.trialsService.create(this.trial).then(trial => {
        this.trial = trial;
        this.showNextScreen();
      });
    }
  }

  /**
   * Answer to a rating screen
   * Event from output of app-emotion-reating component
   * @param value
   */
  rateEmotion(value: number) {
    const key = this.getCurrentEmotion().key;
    this.logger.use.log(`Valeur pour ${key} : ${value * 10}`);
    if (!this.trial.results) { this.trial.results = new Score(); }
    this.trial.results.emotions[key] = value * 10;
    this.showNextScreen();
  }

  /**
   * From a splitter screen, go to rating screen
   * Event from output of app-emotion-splitter component
   */
  goToRating() {
    this.showNextScreen();
  }

  /**
   * Go to next page in form
   * Just notify progression in form to update progressbar
   * Event from output of app-user-form component
   */
  progressForm() {
    this.progressInSurvey();
  }

  /**
   * From a form screen, go to next screen
   * Event from output of app-user-form component
   */
  submitForm(informations) {
    this.trial.informations = informations;
    this.trial.status = ENUM.TrialStatus.INFORMED;
    this.visibility = ENUM.PageBehavior.SURVEY_END;
    this.progressInSurvey();
    this.endSurvey();
  }

  /**
   * Get current used group of emotion
   */
  getCurrentGroup(): Group {
    return this.groups[this.currentIndexGroup];
  }

  /**
   * Get current used emotion
   */
  getCurrentEmotion(): Emotion {
    return this.groups[this.currentIndexGroup].emotions[this.currentIndexEmotion];
  }

  /**
   * Increase progress of the survey
   */
  progressInSurvey() {
    this.currentStep++;
    this.progress = Math.min(100, Math.round(this.currentStep / this.totalStep * 100));
  }

  /**
   * Based on the current status of the survey,
   * go to next screen (rating, splitter, form, end)
   * 1. Check if current emotion is the last of the group
   *  1.1 If false, display next emotion (rating)
   *  1.2 If true, display next group (splitter)
   * 2. Check if current group is the last
   *  2.1 If false, display next group (splitter)
   *  2.2 If true, display form (form)
   */
  showNextScreen() {
    // 1.
    if (this.currentIndexGroup > -1
      && this.currentIndexEmotion + 1 < this.getCurrentGroup().emotions.length) {
      // 1.1
      this.currentIndexEmotion++;
      this.progressInSurvey();
      this.visibility = ENUM.PageBehavior.EMOTION_RATING;
      return;
    }

    // 1.2 & 2.
    if (this.currentIndexGroup + 1 < this.groups.length) {
      this.currentIndexEmotion = -1;
      this.currentIndexGroup++;
      this.progressInSurvey();
      this.visibility = ENUM.PageBehavior.EMOTION_SPLITTER;
      return;
    }

    // 2.2
    this.progressInSurvey();
    this.trial.finished = DateHelper.getUTC();
    this.trial.status = ENUM.TrialStatus.FINISHED;
    this.visibility = ENUM.PageBehavior.USER_FORM;
  }

  /**
   * Save trial results
   * And redirect to BO to generate reports
   */
  endSurvey() {
    const timestamp = Date.now();
    this.trialsService.update(this.trial).then(() => {
      setTimeout(() => { // Wait at least 2s before redirect to BO
        window.location.replace(environment.backofficeUrl + this.trial.id);
      }, 2000 - (Date.now() - timestamp));
    }).catch(error => {
      this.logger.use.error('Impossible de sauvegarder le trial', error);
      const sub = new Subject<void>();
      sub.subscribe(() => this.endSurvey());
      const buttons = [new AlertButton('COMMON.TRY_AGAIN', sub)];
      const alert = new Alert('COMMON.WARNING', 'COMMON.SAVING_ERROR', buttons);
      this.events.openAlert(alert);
    });
  }
}
