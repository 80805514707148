import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Dimension } from '../../models/dimension';
import { LoggerService } from '../../providers/logger.service';
import { SessionService } from '../../providers/session.service';

const OLDEST_YEAR = 1900;

export interface GenericObject {
  code: string;
  text: string;
  order: number;
}

enum FormProgress {
  FIRST = 0,
  SECOND,
  DYNAMIC
}

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

  @Output() forward = new EventEmitter<void>();
  @Output() full = new EventEmitter<{}>();

  public readonly FORM_PROGRESS = FormProgress;
  public readonly DYNAMIC_KEY = 'dynamic';
  public informations: {};
  public progress: FormProgress;
  public dimensions: Dimension[];
  public firstForm: FormGroup;
  public secondForm: FormGroup;
  public dynamicForm: FormGroup;

  public years: GenericObject[];
  public domains: GenericObject[];
  public categories: GenericObject[];
  public status: GenericObject[];

  public sectors: GenericObject[];
  public sizes: GenericObject[];
  public regions: GenericObject[];
  public studies: GenericObject[];
  public seniorities: GenericObject[];

  constructor(
    private formBuilder: FormBuilder,
    private logger: LoggerService,
    private session: SessionService
  ) {
    this.progress = FormProgress.FIRST;
    this.informations = {};
  }

  ngOnInit() {
    this.getStaticLists();
    this.firstForm = this.formBuilder.group({
      gender: [null, Validators.required],
      birthYear: [null, Validators.required],
      domain: [null, Validators.required],
      category: [null, Validators.required],
      status: [null, Validators.required]
    });

    this.secondForm = this.formBuilder.group({
      sector: [null, Validators.required],
      size: [null, Validators.required],
      region: [null, Validators.required],
      studies: [null, Validators.required],
      seniority: [null, Validators.required],
    });

    this.dynamicForm = this.formBuilder.group([]);
    this.getDynamicLists();
  }

  getStaticLists() {

    this.years = [
      { 'order': 1, 'code': '18-29', 'text': '18 - 29 ans' },
      { 'order': 2, 'code': '30-44', 'text': '30 - 44 ans' },
      { 'order': 3, 'code': '45-54', 'text': '45 - 54 ans' },
      { 'order': 4, 'code': '55+', 'text': '55 ans et plus' }
    ];

    this.studies = [
      { 'order': 1, 'code': 'brevet', 'text': 'Inférieur au BAC : CAP / BEP' },
      { 'order': 2, 'code': 'bac', 'text': 'BAC' },
      { 'order': 3, 'code': 'bac2', 'text': 'Bac +2 : DEUG, DUT, BTS' },
      { 'order': 4, 'code': 'bac4', 'text': 'Bac +3 et +4 : Licence, Maîtrise' },
      { 'order': 5, 'code': 'bac5', 'text': 'Bac +5 : Diplôme supérieur' }
    ];

    this.domains = [
      { 'order': 1, 'code': 'private', 'text': 'Secteur privé' },
      { 'order': 2, 'code': 'public', 'text': 'Entreprise publique' }
    ];

    this.categories = [
      { 'order': 1, 'code': 'executive', 'text': 'Cadre supérieur, profession intellectuelle, scientifique, ingénieur, dirigeant ou profession libérale' },
      { 'order': 2, 'code': 'supervisor', 'text': 'Profession intermédiaire, professeur, administratif ou commercial, agent de maîtrise ou technicien' },
      { 'order': 3, 'code': 'employee', 'text': 'Employé' },
      { 'order': 4, 'code': 'worker', 'text': 'Ouvrier qualifié ou non' }
    ];

    this.status = [
      { 'order': 1, 'code': 'no-manager', 'text': 'Non cadre' },
      { 'order': 2, 'code': 'small-manager', 'text': 'Cadre manager de moins de 5 personnes' },
      { 'order': 3, 'code': 'big-manager', 'text': 'Cadre manager de 5 personnes ou plus' }
    ];

    this.sectors = [
      { 'order': 1, 'code': 'industry', 'text': 'Industrie' },
      { 'order': 2, 'code': 'construction-farming', 'text': 'BTP / Agriculture' },
      { 'order': 3, 'code': 'trading', 'text': 'Commerce' },
      { 'order': 4, 'code': 'business', 'text': 'Services aux entreprises' },
      { 'order': 5, 'code': 'particular', 'text': 'Services aux particuliers' },
      { 'order': 6, 'code': 'administration', 'text': 'Administration' }
    ];

    this.sizes = [
      { 'order': 1, 'code': 'small', 'text': 'TPE : Moins de 10 salariés' },
      { 'order': 2, 'code': 'medium', 'text': 'PME : De 10 à 250 salariés' },
      { 'order': 3, 'code': 'large', 'text': 'ETI : De 250 à 5 000 salariés' },
      { 'order': 4, 'code': 'big', 'text': 'Grande Entreprise : 5 000 salariés et plus' }
    ];

    this.regions = [
      { 'order': 1, 'code': 'middle', 'text': 'Île de France' },
      { 'order': 2, 'code': 'nw', 'text': 'Nord-Ouest' },
      { 'order': 3, 'code': 'ne', 'text': 'Nord-Est' },
      { 'order': 4, 'code': 'se', 'text': 'Sud-Est' },
      { 'order': 5, 'code': 'sw', 'text': 'Sud-Ouest' },
      { 'order': 6, 'code': 'outside', 'text': 'Outre-mer' }
    ];

    this.seniorities = [
      { 'order': 1, 'code': '-2years', 'text': 'Moins de 2 ans' },
      { 'order': 2, 'code': '2-5years', 'text': 'De 2 à 5 ans' },
      { 'order': 3, 'code': '5-15years', 'text': 'De 5 à 15 ans' },
      { 'order': 4, 'code': '16+years', 'text': '16 ans et plus' },
    ];
  }

  getDynamicLists() {
    this.dimensions = [];
    const dimensions = this.session.campaign.dimensions;
    const formObject = {};
    if (dimensions && dimensions.length > 0) {
      dimensions.forEach((dimension, index) => {
        formObject[this.DYNAMIC_KEY + index] = [null, Validators.required];
      });
      this.dynamicForm = this.formBuilder.group(formObject);
      this.dimensions = dimensions;
    }
  }

  submitFirstForm() {
    if (!this.firstForm.valid) {
      this.logger.use.error('Form error', this.firstForm, this.dynamicForm);
      return;
    }

    this.informations['gender'] = this.firstForm.controls['gender'].value;
    this.informations['birthYear'] = this.firstForm.controls['birthYear'].value;
    this.informations['domain'] = this.firstForm.controls['domain'].value;
    this.informations['category'] = this.firstForm.controls['category'].value;
    this.informations['status'] = this.firstForm.controls['status'].value;

    this.forward.emit();
    this.progress = this.FORM_PROGRESS.SECOND;
  }

  submitSecondForm() {
    if (!this.secondForm.valid) {
      this.logger.use.error('Form error', this.secondForm);
      return;
    }

    this.informations['sector'] = this.secondForm.controls['sector'].value;
    this.informations['size'] = this.secondForm.controls['size'].value;
    this.informations['region'] = this.secondForm.controls['region'].value;
    this.informations['studies'] = this.secondForm.controls['studies'].value;
    this.informations['seniority'] = this.secondForm.controls['seniority'].value;

    if (this.dimensions.length < 1) {
      this.submitAllInformations();
    } else {
      this.forward.emit();
      this.progress = this.FORM_PROGRESS.DYNAMIC;
    }
  }

  submitDynamicForm() {
    if (!this.dynamicForm.valid) {
      this.logger.use.error('Form error', this.dynamicForm);
      return;
    }

    for (let i = 0; i < this.dimensions.length; i++) {
      this.informations[this.DYNAMIC_KEY + i] = this.dynamicForm.controls[this.DYNAMIC_KEY + i].value;
    }

    this.submitAllInformations();
  }

  submitAllInformations() {
    this.full.emit(this.informations);
  }
}
