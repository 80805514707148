import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Emotion } from '../../models/emotion';
import { LoggerService } from '../../providers/logger.service';

// If you change FORBIDDEN_VALUE value,
// you need to update _slider.scss
// .noUi-handle[aria-valuetext='FORBIDDEN_VALUE'] rule
const MIN_VALUE = 0;
const MAX_VALUE = 10;
const FORBIDDEN_VALUE = 11;
const FIRST_STEP = 0.1;
const NORMAL_STEP = 1;

@Component({
  selector: 'app-emotion-rating',
  templateUrl: './emotion-rating.component.html',
  styleUrls: ['./emotion-rating.component.scss']
})
export class EmotionRatingComponent implements OnInit, OnChanges {

  public noValue: boolean;
  public min: number = MIN_VALUE;
  public max: number = FORBIDDEN_VALUE;
  public step: number = FIRST_STEP;
  public value: number = FORBIDDEN_VALUE;
  public config = this.getConfig();

  @ViewChild('frequencySlider') mySlider;
  @Input() emotion: Emotion;
  @Output() rate = new EventEmitter<number>();

  constructor(
    private logger: LoggerService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.setRangeForFirstClick();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setRangeForFirstClick();
  }

  getCursors(emotion: Emotion) {
    return new Promise(resolve => {
      const list = [];
      const keys = Object.keys(emotion.cursors);
      keys.forEach(key => { list.push(emotion.cursors[key]); });
      this.translate.get(list).subscribe(array => {
        keys.forEach(key => {
          const value = emotion.cursors[key];
          const trad = array[value];
          emotion.cursors[key] = trad;
        });
        resolve(emotion.cursors);
      });
    });
  }

  /**
   * Set slider config to first click config
   */
  setRangeForFirstClick() {
    this.noValue = true;
    this.step = FIRST_STEP; // Set step to 0.1 to know the exact point where the user clicked
    this.max = FORBIDDEN_VALUE; // Set max value to the forbidden value
    this.value = FORBIDDEN_VALUE; // Set value to 11 to hide first value (forbidden/null value)
    if (this.mySlider && this.mySlider.slider) {
      this.mySlider.slider.updateOptions({range: {min: MIN_VALUE, max: FORBIDDEN_VALUE}}); // Avoid graphical bug
    }

    this.getCursors(this.emotion).then(() => {

    });
  }

  /**
   * Set slider config to normal config
   */
  setRangeAfterFirstClick() {
    this.noValue = false;
    this.step = NORMAL_STEP;
    this.max = MAX_VALUE;
  }

  /**
   * Catch click on continue
   * Send emotion value to parent
   */
  submitRate() {
    const value = this.mySlider.slider.get();
    this.rate.emit(value);
  }

  /**
   * Catch rate changing
   * If it's the first click, value have to be normalize from 0 to 11 to 0 to 10
   * 1. Calculate gap percent between selected value & 11
   * 2. Calculate real value on 10
   * 3. Modify slider config to select between 0 & 10
   * 4. Set the real value on slider
   * @param value
   */
  rateUpdated(value) {
    if (this.noValue) {
      const ration = Math.round(value) / FORBIDDEN_VALUE; // 1.
      const state = Math.round(value - ration); // 2.
      this.setRangeAfterFirstClick(); // 3.
      this.value = state; // 4.
    }
  }

  /***
   * NoUiSlider config
   * @see https://refreshless.com/nouislider/
   * @see https://refreshless.com/nouislider/pips/
   */
  getConfig() {
    return {
      behaviour: 'tap-drag',
      connect: true, // The connect option can be used to control the bar between the handles or the edges of the slider
      tooltips: true, // A basic tooltip, showing current value
      range: { // Max & min value bounds
        min: MIN_VALUE,
        max: FORBIDDEN_VALUE
      },
      pips: { // Tick text
        mode: 'count',
        density: MAX_VALUE, // The density value controls how many pips are placed on one percent of the slider range
        values: 3, // Number of pips under tick step
        stepped: false, // the stepped option can be set to true to match the pips to the slider steps.
        format: {to: this.getTextFormatter()} // Format tick text
      }
    };
  }

  /**
   * Format boundaries text
   */
  getTextFormatter() {
    return function updatePips( value, type ) {
      return '';
    };
  }
}
