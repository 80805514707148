import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NouisliderModule } from 'ng2-nouislider';
import { LoggerModule } from 'ngx-logger';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmotionRatingComponent } from './components/emotion-rating/emotion-rating.component';
import { EmotionSplitterComponent } from './components/emotion-splitter/emotion-splitter.component';
import { GlobalAlertComponent } from './components/global-alert/global-alert.component';
import { GlobalLoaderComponent } from './components/global-loader/global-loader.component';
import { GlobalWaitingComponent } from './components/global-waiting/global-waiting.component';
import { HomeComponent } from './components/home/home.component';
import { LandingComponent } from './components/landing/landing.component';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import { StyleGuideComponent } from './components/style-guide/style-guide.component';
import { SurveyEndComponent } from './components/survey-end/survey-end.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { UserIdentificatorComponent } from './components/user-identificator/user-identificator.component';
import { CampaignsService } from './providers/campaigns.service';
import { GlobalEventsService } from './providers/global-events.service';
import { IsDevGuardService } from './providers/guards/is-dev-guard.service';
import { RefreshGuardService } from './providers/guards/refresh-guard.service';
import { LoggerService } from './providers/logger.service';
import { ApiBemoService } from './providers/requests/api-bemo.service';
import { ApiLocalService } from './providers/requests/api-local.service';
import { SessionService } from './providers/session.service';
import { TrialsService } from './providers/trials.service';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GlobalLoaderComponent,
    GlobalAlertComponent,
    GlobalWaitingComponent,
    StyleGuideComponent,
    UserIdentificatorComponent,
    UserFormComponent,
    EmotionRatingComponent,
    EmotionSplitterComponent,
    SurveyEndComponent,
    LandingComponent,
    ProgressbarComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NouisliderModule,
    AppRoutingModule,
    LoggerModule.forRoot({
      serverLoggingUrl: environment.logs.serverLoggingUrl,
      level: environment.logs.level,
      serverLogLevel: environment.logs.serverLogLevel
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    // Guards
    IsDevGuardService,
    RefreshGuardService,

    // Requests
    HttpClient,
    ApiBemoService,
    ApiLocalService,

    LoggerService,
    GlobalEventsService,
    TrialsService,
    CampaignsService,
    SessionService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
