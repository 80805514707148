export namespace BEMO {

  export const ROUTES = {
    AUTH: {
      url: 'auth'
    },
    HOME: {
      url: 'survey'
    },
    STYLEGUIDE: {
      url: 'styleguide',
    }
  };

  export const DEFAULT_ROUTE = ROUTES.AUTH;
}
