import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpService } from './http.service';
import { LoggerService } from '../logger.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiBemoService extends HttpService {

  constructor(
    protected http: HttpClient,
    protected logger: LoggerService
  ) {
    super(http, logger);
  }

  public prefixAndSuffixUrl(url) {
    const databaseKey = environment.database.token;
    url = environment.database.url + '/' + url;
    if (url.indexOf('?') !== -1) {
      const splits = url.split('?');
      url = `${splits[0]}.json?auth=${databaseKey}&${splits[1]}`;
    } else {
      url = `${url}.json?auth=${databaseKey}`;
    }
    return url;
  }

}
