import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ENUM } from '../../models/enum';
import { LoggerService } from '../../providers/logger.service';
import { SessionService } from '../../providers/session.service';
import { TrialsService } from '../../providers/trials.service';

@Component({
  selector: 'app-user-identificator',
  templateUrl: './user-identificator.component.html',
  styleUrls: ['./user-identificator.component.scss']
})
export class UserIdentificatorComponent {

  @Output() identify: EventEmitter<string> = new EventEmitter();
  public form: FormGroup;
  public error: string;

  constructor(
    private session: SessionService,
    private trialService: TrialsService,
    private formBuilder: FormBuilder,
    private logger: LoggerService
  ) {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
  }

  submit() {
    this.error = null;

    if (!this.form.valid) {
      this.logger.use.error('Form error', this.form);
      return;
    }

    const id = this.session.campaign.id;
    const email = this.form.value.email.toLowerCase();
    this.trialService.fetchByEmailAndCampaignId(email, id).then(done => {
      if (done && done.status >= ENUM.TrialStatus.INFORMED) {
        this.cantAccessSurvey();
      } else {
        this.session.trial = done;
        this.allowToAccessSurvey(email);
      }
    });
  }

  allowToAccessSurvey(email: string) {
    this.identify.emit(email);
  }

  cantAccessSurvey() {
    this.error = 'USER_IDENTIFICATOR.ALREADY_DONE';
  }
}
