import { Injectable } from '@angular/core';

import { Group } from '../models/emotion';
import { Trial } from '../models/trial';
import { LoggerService } from './logger.service';
import { ApiBemoService } from './requests/api-bemo.service';
import { ApiLocalService } from './requests/api-local.service';

@Injectable()
export class TrialsService {
  private readonly RESSOURCE_LIBRARY = 'trials';

  constructor(
    private logger: LoggerService,
    private apiLocal: ApiLocalService,
    private apiBemo: ApiBemoService
  ) {}

  public fetchAllEmotions(): Promise<Group[]> {
    const url = `bemo.json`;
    return this.apiLocal.get(url).then(rawObject => {
      const groups = Group.rawToArray(rawObject);
      this.logger.use.log('Groupe d\'émotions : ', groups);
      return groups;
    });
  }

  public fetchByEmailAndCampaignId(email: number, campaignId: string): Promise<Trial> {
    const url = `${this.RESSOURCE_LIBRARY}?orderBy="identity/email"&equalTo="${email}"`;
    return this.apiBemo.get(url).then((dtoAccounts: Object) => {
      const keys = Object.keys(dtoAccounts);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const trial = dtoAccounts[key];
        if (trial.campaignId === campaignId) {
          return trial;
        }
      }
      return null;
    });
  }

  public create(trial: Trial): Promise<Trial> {
    const url = `${this.RESSOURCE_LIBRARY}/`;
    return this.apiBemo.post(url, trial)
      .then((res: any) => {
        trial.id = res.name;
        return trial;
      });
  }

  public update(trial: Trial): Promise<any> {
    const url = `${this.RESSOURCE_LIBRARY}/${trial.id}`;
    return this.apiBemo.put(url, trial);
  }
}
