import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { LandingComponent } from './components/landing/landing.component';
import { StyleGuideComponent } from './components/style-guide/style-guide.component';
import { BEMO } from './constants';
import { IsDevGuardService } from './providers/guards/is-dev-guard.service';
import { RefreshGuardService } from './providers/guards/refresh-guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: `/${BEMO.DEFAULT_ROUTE.url}`,
    pathMatch: 'full'
  },
  {
    path: BEMO.ROUTES.AUTH.url,
    component: LandingComponent
  },
  {
    path: BEMO.ROUTES.AUTH.url + '/:id',
    component: LandingComponent
  },
  {
    path: BEMO.ROUTES.HOME.url,
    component: HomeComponent,
    canActivate: [RefreshGuardService]
  },
  {
    path: BEMO.ROUTES.STYLEGUIDE.url,
    component: StyleGuideComponent,
    canActivate: [IsDevGuardService]
  },
  {
    path: '**', // 404
    redirectTo: `/${BEMO.DEFAULT_ROUTE.url}`,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { useHash: false/*, enableTracing: true*/ } // enableTracing to debug
    )
  ],
  exports: [
    RouterModule,
  ],
  providers: []
})

export class AppRoutingModule { }
